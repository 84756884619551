<template>
  <div class="tabled">
    <div class="tabled-cell Nunito">
    <img src="https://i.giphy.com/media/26u4b45b8KlgAB7iM/giphy.webp">
      <h1 class="is-size-3">{{ $t("logout.i3") }}</h1>
      <h1 class="is-size-5">{{ $t("logout.i5") }}</h1>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Logout',
    components: {},
    async created () {
      if (this.$store.getters.isLoggingOut) return
      await this.$store.dispatch('sleep', 3000)
      const _this = this
      this.$store.dispatch('logout').then((g) => {
        _this.$store.commit('themeLang', 'en')
        _this.$root.$i18n.locale = 'en'
        this.$router.push({ path: '/', query: { ref: 'logout' } })
        console.log(g, 'Logout then')
      }).catch((g) => {
        console.log(g, 'Logout Error')
        this.$router.push({ path: '/', query: { ref: 'logout' } })
      })
    }
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
</style>
